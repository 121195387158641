import { Box, Dialog } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import styles from './popupStyles.module.css'

export function Call(value) {
    /* console.log(getDec(value,"Call"))
    window.open("tel:" + getDec(value,"Call")); */
    getDec(value, "Call")
        .then((result) => {
            window.open("tel:" + result,"_self")
            console.log(result)
        })
        .catch(err => alert(err))
}

export function Email(value) {
    getDec(value, "Email")
        .then((result) => {
            window.open(
                "mailto:" + result,
                "popUpWindow",
                "height=400,width=600,left=10,top=10,,scrollbars=yes,menubar=no", "_self"
            )
        })
        .catch(err => alert(err))
    /* window.open(
      "mailto:" + value,
      "popUpWindow",
      "height=400,width=600,left=10,top=10,,scrollbars=yes,menubar=no"
    ) */
}
export function WhatsApp(value) {
    getDec(value, "WhatsApp")
        .then((result) => { window.open("https://api.whatsapp.com/send?phone=" + result + "b&text=%20Heyy!", "_self") })
        .catch(err => alert(err))

    /* window.open("https://api.whatsapp.com/send?phone=" + value + "b&text=%20Heyy!") */
}

export function Instagram(value) {
    getDec(value, "Instagram")
        .then((result) => { window.open("https://www.instagram.com/" + result, "_self") })
        .catch(err => alert(err))

    /* 
        window.open("https://www.instagram.com/" +value) */
}

export function Website(value) {

    window.open("https://" + value, "_self")
}

export function WebsitePopup({ value, websitePopup, setWebsitePopup }) {
    var popupImage = "https://reality-reach-assets.storage.googleapis.com/vision/general-assets/dialogTiles/popup_web.png"
    const [url, setUrl] = useState(null)

    useEffect(() => {
        if (websitePopup) {
            getDec(value, "Website")
                .then((result) => { setUrl(result) })
                .catch(err => /* alert(err) */setUrl(err))
        }
    }, [websitePopup])

    return websitePopup ? (
        <>
            <div className={/* !url ? styles.WebsitePopup :  */styles.WebsiteTransitionIn} >
                <img
                    onClick={() => {
                        /* Website(url) */
                    }}
                    width='483px'
                    height='726px'
                    /*  width='80%' */
                    src={popupImage}
                />
            </div>
        </>) : null
}

export function ARWebsitePopup({ value, websitePopup, setWebsitePopup }) {
    var popupImage = "https://reality-reach-assets.storage.googleapis.com/vision/general-assets/dialogTiles/popup_web.png"
    const [url, setUrl] = useState(null)

    useEffect(() => {
        if (websitePopup) {
            getDec(value, "Website")
                .then((result) => { setUrl(result) })
                .catch(err => /* alert(err) */setUrl(err))
        }
    }, [websitePopup])

    return (
        <>
            <img
                src="https://reality-reach-assets.storage.googleapis.com/vision/general-assets/dialogTiles/popup_web.png"
                style={{ display: 'none' }} alt=""
                rel="preload" as="image" />
            <div
                style={{
                    width: '100vw',
                    height: '100vh', alignItems: 'center'
                }}>
                <Dialog
                    /* fullWidth */
                    sx={{
                        /* width: '90vw', */
                        /*  height: `${1.5}*80vw`, */
                        p: 0,
                        m: 0,
                        '& .MuiDialog-paper': {
                            backgroundColor: 'black',
                            opacity: 0.8,
                            p: 0,
                            borderRadius: '7vw'
                            /*  padding: '20px' */
                        },
                        '& .MuiPaper-root': {
                            backgroundColor: 'black',
                            opacity: 0.8,
                        },
                        /* alignSelf:'center' */
                    }} open={websitePopup} onClose={() => { setWebsitePopup(false) }}>

                    <Box sx={{ width: '30%', height: '20%' }}
                        onClick={() => { setWebsitePopup(false) }}
                        style={{ position: 'fixed', left: '10%', top: '65%' }} />

                    <Box sx={{ width: '30%', height: '20%' }}
                        onClick={() => { window.open(url, '_blank') }}
                        style={{ position: 'fixed', right: '10%', top: '65%' }} />

                    <img
                        onClick={() => {
                            /* Website(url) */
                        }}
                        width='100%'
                        height='100%'
                        /*  width='80%' */
                        src={popupImage}
                    />
                </Dialog>

            </div>

        </>)
}

export function LinkedIn(value) {
    getDec(value, "LinkedIn")
        .then((result) => { window.open(result, "_self") })
        .catch(err => alert(err))
    /*   window.open(value) */
}


async function getDec(query, type) {
    const optionss = await axios.post(process.env.REACT_APP_ENCRYPTION_SERVER+'/api/decryption', {
        query: query,
        type: type,
    })
    console.log(optionss.data.data)
    return optionss.data.data
}
