

import '../styles.css'
import { Button, Stack } from '@mui/material'

import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import { Link } from 'react-router-dom';
import VisionStatement from './VisionStatement';

export default function ScanningOverlay({ profileSnapshot }) {

    return (<>
        <div id="example-scanning-overlay"
            className="hidden">

            <img className="vision"
                src="https://cdn.discordapp.com/attachments/1069195643211481138/1074282505504559165/LOGO_VISION_SmallWIDE.png" />
            <div className='instructions' >
                <img
                    src="https://cdn.discordapp.com/attachments/1069195643211481138/1074282506012086353/gif_STILL.png" />
                <p>Point your camera at the card</p>
                <p className='visionstatement'>
                    {profileSnapshot ?
                        (<VisionStatement {...{ profileSnapshot }} />) :
                        null}
                </p>

                <Button variant='contained'
                    startIcon={<ViewAgendaOutlinedIcon />}
                    sx={{
                        top: '40vh',
                        color: 'white',
                        backgroundColor: 'black',
                        opacity: 0.8,
                        borderRadius: 10
                    }}
                    onClick={() => { window.open('/static', '_self') }}
                > View Static
                </Button>
            </div>

            <div className="inner centralImages">
                <Stack>
                    <img src="https://cdn.discordapp.com/attachments/1069195643211481138/1074282505789780099/STROKE_Wide.png" />
                    <img src="https://reality-reach-assets.storage.googleapis.com/ar-card/markers/Binary.png" />
                    <img src="https://cdn.discordapp.com/attachments/1069195643211481138/1074282505789780099/STROKE_Wide.png" />
                </Stack>
                <div className="scanline">
                    <img src="https://cdn.discordapp.com/attachments/1069195643211481138/1074282505789780099/STROKE_Wide.png" />
                </div>
            </div>

        </div>
    </>)
}