import Flappy from "./Flappy/flappy";



export default function CanvasManager({ uid, profileHash, authStatus,

    aframeComponentsInitialized, setAframeComponentsInitialized,

    GameState, setGameState, jumping, GameUI,

    sceneRef, tabHasFocus, uiVisible, setUiVisible, setMode }) {
    return (<Flappy {...{
        uid, GameUI,
        aframeComponentsInitialized, setAframeComponentsInitialized,
        uiVisible, setUiVisible,
        jumping,
        GameState, setGameState

    }} />)
}