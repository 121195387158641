import { Typography } from "@mui/material"
import { useEffect, useState } from "react"



export default function VisionStatement({ profileSnapshot }) {

    const [visionStatement, setVisionStatement] = useState({
        Statement: null,
        Title: null
    })
    useEffect(() => {

        if (profileSnapshot) {
            if (profileSnapshot.UserDetails) {
                if (profileSnapshot.UserDetails.VisionStatement) {
                    setVisionStatement({
                        Statement: profileSnapshot.UserDetails.VisionStatement.Statement,
                        Title: profileSnapshot.UserDetails.VisionStatement.Title
                    })
                }
            }
        }
    }, [])
    return (
        <div style={{ top: 'inherit', }} className='pig'>
            <Typography sx={{ color: 'white', fontSize: 14, textTransform: "uppercase" }}
                textAlign='center'>"{visionStatement.Statement}"
            </Typography>
            <br></br>
            <Typography
                /*      style={{ top: '40vh' }} */
                sx={{ color: 'white', fontSize: 14, textTransform: "capitalize" }}
                textAlign='center'>
                {visionStatement.Title}
            </Typography>

        </div>
    )
}