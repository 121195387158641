
import axios from 'axios'
import {
    Dialog, DialogContent, Button, Box,
    Stack, DialogActions, Typography
} from '@mui/material'
import ReplyIcon from '@mui/icons-material/Reply';
import styles from './VR.module.css'

export default function VRexperienceManager({ setMode, vrConfig }) {

    async function getDec(query, type) {
        const optionss = await axios.post('https://email-mailgun-backend.herokuapp.com/api/decryption', {
            query: query,
            type: type,
        })
        console.log(optionss.data.data)
        return optionss.data.data
    }


    function LinkedIn(value) {
        getDec(value, "LinkedIn")
            .then((result) => { window.open(result) })
            .catch(err => alert(err))
        /*   window.open(value) */
    }

    function DoNothing() {
    }




    return (<>

        {/* <img
            style={{
                top: '90vh',
                zIndex: 9, cursor: 'pointer', opacity: "0.6"
            }}
            className={styles.button}
            onClick={() => { setMode("AR") }}
            src="https://armeta-card-builder.appspot.com.storage.googleapis.com/manual/Ar.Shraddha/BackButton.png">
        </img> */}
        <Button
            variant='contained'
            startIcon={<ReplyIcon />}
            sx={{
                backgroundColor: 'black', color: 'white',
                borderRadius: 10,
                zIndex: 9, top: '80vh',
                opacity: 0.8
            }}
            className={styles.button}
            onClick={() => setMode("AR")}
        >
            Go Back
        </Button>


        {/* {vrConfig.LeadGeneration ? (
            <>
                <div>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: 'black', color: 'white',
                            borderRadius: 10,
                            zIndex: 9, top: '80vh',
                            opacity: 0.8
                        }}
                        className={styles.button}
                        onClick={() => setMode("AR")}
                    >
                        {vrConfig.ButtonPrompt}
                    </Button>
                </div>

                <DialogPopup />
            </>
        ) : (null)} */}

        <a-scene
            cursor="rayOrigin: mouse">

            <a-entity raycaster="showLine: true; far: 100;  
                         lineOpacity: 0.5"></a-entity>
            <a-sky
                src={vrConfig.Image}
            ></a-sky>

        </a-scene>

    </>
    )
}




export function DialogPopup({ page, setPage }) {


    const handleClose = () => {
        setPage("PageOne")
    }



    return (<>

        <Dialog open={page == "Details"} onClose={handleClose}
            fullWidth
            maxWidth='xl'
            sx={{
                height: '100vh',
                margin: 0,
                borderRadius: 40,
                '& .MuiDialogContent-root': {
                    padding: 0,
                },
                '& .MuiDialog-root': {
                    borderRadius: '10px',
                },
                '& .MuiPaper-root': {
                    borderRadius: 10
                },
            }}>

            <DialogContent>
                {/* <DialogTitle>
            FOOOOOD
          </DialogTitle> */}
                <Stack direction="column"
                    justifyContent="center"
                    alignItems="center" spacing={1} >
                    <iframe src={`https://documentcloud.adobe.com/view-sdk-demo/PDFs/Bodea%20Brochure.pdf#view=fitH`}
                        title="testPdf"
                        style={{ height: '100vh' }}
                        width="100%" />
                    <Button onClick={handleClose}
                        variant='contained'
                        style={{ position: 'fixed', top: '90%' }}
                        sx={{ size: 'small', backgroundColor: 'black', color: 'white', borderRadius: 10 }}
                    >
                        Close</Button>
                    {/*                     <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
 */}                </Stack>


            </DialogContent>

        </Dialog>
    </>)
}

