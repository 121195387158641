
import { useState, useEffect, useRef } from "react";
import FlappyOnScreenInstructions from "./FlappyOnScreenInstructions";
/* import * as THREE from 'three'; */

export default function Flappy({ uid,
  GameUI,
  aframeComponentsInitialized, setAframeComponentsInitialized,
  uiVisible, setUiVisible,
  jumping,
  GameState, setGameState }) {


  const Scores = useRef({
    highScore: 0,
    score: 0
  })

  const [occluderReady, setOccluderReady] = useState(false)

  var cloudsImage = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/gamification/dino/cloud_single.png'
  var character = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/gamification/flappy/character.png'
  var button = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/gamification/flappy/PlayButton.gif'

  var GameUIdashboard = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/gamification/GameUIdashboard.png'
  var GameStartButton = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/canvas/canvasInteraction/StartGameButton.png'

  var jumpCount = 0
  var distance = 0
  var MainObstacleHeight = 0.15
  var scoreUpdate = 0



  const canvasHeight = 0.45;
  const canvasWidth = 0.7;
  const holeSize = 0.4 * canvasHeight;
  function randomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }
  useEffect(() => {

    const AFRAME = window.AFRAME
    if (AFRAME && !aframeComponentsInitialized.Canvas) {
      AFRAME.registerComponent("obstacle", {
        init: function () {
          console.log("registered")
          this.xMax = canvasWidth / 2;  // x upper boundary
          this.xMin = -canvasWidth / 2; // x lower boundary
          this.speed = 0.00020; // base movement speed
          this.dir = -1;   // animation direction 
          this.el.object3D.position.x = this.xMax
        },
        changeSpeed(spd) {
          this.speed = spd;
        },
        tick: function (t, dt) {
          const obstaclePair = document.getElementById("obstacle2");
          const character = document.getElementById("char");
          // grab the underlying THREE.js position
          const pos = this.el.object3D.position
          const scale = this.el.object3D.scale

          const obsPairPos = obstaclePair.object3D.position
          const obsPairScale = obstaclePair.object3D.scale

          const characterPosition = character.object3D.position

          const scoreText = document.getElementById("score")

          // check if the object has passed the boundaries - if so, change the direction
          if (pos.x < this.xMin) {
            pos.x = this.xMax;
            obsPairPos.x = this.xMax;
            var height = randomNumber(0.1 * canvasHeight, canvasHeight - holeSize - 0.1);
            var obsPairHeight = canvasHeight - height - holeSize;
            obsPairScale.y = obsPairHeight / 0.15
            obsPairPos.y = canvasHeight - obsPairHeight / 2 - 0.225
            pos.y = height / 2 - 0.225;
            scale.y = height / 0.15;
            MainObstacleHeight = height;
            console.log(MainObstacleHeight)

          }
          // update the position using the direction and speed
          if (!GameState.Paused || !GameState.Over) {

            if (pos.x < -0.45 * canvasWidth) {
              //collision Check
              console.log("check")

              if (characterPosition.y < MainObstacleHeight + 0.025 - 0.225) {
                this.el.pause()
                character.pause()
                //alert("Collision")
                //GameState.current.Over = true
                setGameState(prevState => { return { ...prevState, Over: true } })
                console.log("Collision at " + MainObstacleHeight)
              }

            }

            if (this.speed + t / 500000000 > 0.0008) {
              pos.x += this.dir * (0.0006) * dt;
              obsPairPos.x += this.dir * (0.0006) * dt;
              distance += 0.0006 * dt
              //Scores.current.score += 0.0006 * dt
            }
            else {
              pos.x += this.dir * (this.speed + t / 500000000) * dt;
              obsPairPos.x += this.dir * (this.speed + t / 500000000) * dt;
              distance += this.dir * (this.speed + t / 500000000) * dt;
              // console.log(-distance * 100)
              //Scores.current.score += (this.speed + t / 500000000) * dt
            }

            if ((-distance * 100) - scoreUpdate > 10) {
              scoreUpdate = Math.ceil(-distance * 100 / 10) * 10
              scoreText.setAttribute("text",
                "value: " + (scoreUpdate).toString()
              )
            }
            //console.log(distance)
          }
        }
      })

      AFRAME.registerComponent("charactercontroller", {
        init: function () {

          console.log("character controller registered")
          this.speed = 0.00025; // base movement speed
          this.dir = -1;   // animation direction 
          const pos = this.el.object3D.position

          this.el.setAttribute("animation", {
            property: "rotation",
            from: "0 0 0",
            to: "0 0 -90",
            dur: 200,
            'startEvents': 'jumpRotate',
            easing: "easeInOutQuad",
          });
          /* this.el.setAttribute("animation__position", {
            property: "position",
            from: "-0.35 " + GetPosition().toString() + " 0",
            to: "-0.35 " + (GetPosition() + 0.02 * canvasHeight).toString() + " 0",
            dur: 100,
            'startEvents': 'jumpRotate',
            easing: "easeInOutQuad",
          }); */

          this.el.setAttribute("animation__scale", {
            property: "scale",
            from: "1 1 1",
            to: "1.2 1.2 1",
            dur: 200,
            'startEvents': 'jumpScale',
            easing: "easeInOutQuad",
          });
          this.el.setAttribute("animation__scale2", {
            property: "scale",
            from: "1.2 1.2 1",
            to: "1 1 1",
            dur: 200,
            delay: 50,
            'startEvents': 'jumpScale',
            easing: "easeInOutQuad",
          });
        },
        changeSpeed(spd) {
          this.speed = spd;
        },
        tick: function (t, dt) {
          const pos = this.el.object3D.position
          const scale = this.el.object3D.scale
          const obstacle = document.getElementById("obstacle");
          const obstaclePair = document.getElementById("obstacle2");
          /*   if (!gamePaused || !gameOver) { */
          if (!GameState.Paused || !GameState.Over) {
            if (!jumping.current.jumping) {
              pos.y -= 0.5 * this.speed * dt;
            }
            else {
              console.log("jump positive entered")
              pos.y += 0.7 * this.speed * dt;
              jumpCount += 1
              if (jumpCount == 20) {
                jumpCount = 0
                jumping.current.jumping = false
              }
              //alert("jumping")
            }
          }

          /////Canvas bounds
          if (pos.y <= -0.225 + 0.025 || canvasHeight - 0.225 - 0.025 < pos.y) {
            setGameState(prevState => { return { ...prevState, Over: true } })
            this.el.pause()
            obstacle.pause()
          }
        }
      })

      AFRAME.registerComponent("occluder", {
        init: function () {
          const THREE = window.THREE
          this.el.addEventListener('model-loaded', () => {

            this.el.getObject3D('mesh').traverse((o) => {
              if (o.isMesh) {
                const material = new THREE.MeshStandardMaterial({
                  colorWrite: false,
                });
                o.material = material;
              }
            });
            setOccluderReady(true)
          })
        }
      })

      AFRAME.registerComponent("dashboardcontroller", {
        init: function () {

          console.log("initializing dashboard")

          this.el.setAttribute("animation", {
            property: "position",
            from: "0 0 -0.04",
            to: "0 0.42 -0.04",
            dur: 2000,
            'startEvents': 'GameStart'
          });

          this.el.setAttribute("animation__2", {
            property: "position",
            from: "0 0.42 -0.04",
            to: "0 0 -0.04",
            dur: 1000,
            'startEvents': 'GameEnd',
          });
        }
      })

      AFRAME.registerComponent("cloud", {
        init: function () {
          this.xMax = canvasWidth / 2;  // x upper boundary
          this.xMin = -canvasWidth / 2; // x lower boundary
          this.speed = 0.00005; // base movement speed
          this.dir = -1;   // animation direction 

        },
        init: function () {
          var fromString = (canvasWidth / 2).toString() + " 0.18 0"
          var toString = (-canvasWidth / 2).toString() + " 0.18 0"
          this.el.setAttribute("animation__position", {
            property: "position",
            from: fromString,
            to: toString,
            dur: 5000,
            delay: 1000,
            easing: "linear",
            loop: -1
          });
        },
        changeSpeed(spd) {
          this.speed = spd;
        },

      })

      setAframeComponentsInitialized(prevState => {
        return {
          ...prevState,
          Canvas: true
        }
      })
    }


  }, [window])

  useEffect(() => {

  }, [GameState])




  /*   var geometry = new THREE.BoxGeometry(3, 3, 3);
    mesh = new THREE.Mesh(geometry, material.clone());
    mesh.material.color.set(0x0000ff);
    mesh.material.colorWrite = false; // <=================
    mesh.renderOrder = 2;
    mesh.position.z = 10;
    scene.add(mesh);
   */

  function EmitGameEndEvents() {
    var portfolio = document.getElementById("PortfolioOverlay")

    var dashboard = document.getElementById("gameDashboard")
    dashboard.emit("GameEnd", null)

    portfolio.emit("endGame", null)
    setUiVisible(true)
    setGameState(prevState => {
      return {
        ...prevState,
        Over: true,
        Start: false,
        inGame: false
      }
    })
  }

  function EmitGameStartEvents() {

    var dashboard = document.getElementById("gameDashboard")
    dashboard.emit("GameStart", null)

    setTimeout(() => setGameState(prevState => {
      return {
        ...prevState, Start: true, inGame: true
      }
    }), 2500);

    var portfolio = document.getElementById("PortfolioOverlay")

    portfolio.emit("startGame", null)
    setTimeout(() => setUiVisible(false), 500)
  }

  function RequestGameStart() {
    EmitGameStartEvents()
  }

  return (<>

    <FlappyOnScreenInstructions {...{
      GameState, setGameState,
      EmitGameEndEvents
    }} />


    {GameState.Start ?

      (<>
        <a-plane obstacle
          color="#4d4d4d"
          id="obstacle" height="0.15" width="0.0275" position="0.35 -0.15 0"
        ></a-plane>
        <a-plane id="obstacle2"
          color="#4d4d4d"
          height="0.15" width="0.0275" position="0.35 0.15 0"
        ></a-plane>
      </>)
      :
      (<></>)}

    <a-plane cloud id="cloud1" height="0.04" width="0.112"
      src={cloudsImage} opacity="0.99" position="0.3 0.18 0"></a-plane>

    {/*     <a-plane cloud id="cloud2" height="0.04" width="0.112"
      src={cloudsImage} opacity="0.99" position="0.2 -0.12 0"></a-plane>

    <a-plane cloud id="cloud3" height="0.04" width="0.112"
      src={cloudsImage} opacity="0.99" position="-0.1 0.15 0"></a-plane>
 */}
    <a-entity
      id="score"
      color="white"
      width="2"
      position="0.45 0.4 0"
      text="color:white;shader: msdf; font:https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/pressstart2p/PressStart2P-Regular.json;"
    ></a-entity>

    {/* {!GameState.Over ?
      (<a-entity
        id="instructions"
        color="white"
        width="2"
        position="0.5 -0.4 0"
        text="color:white;value:''Tap Anywhere'' ;shader: msdf; font:https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/pressstart2p/PressStart2P-Regular.json;"
      ></a-entity>) :
      (<a-entity
        id="instructions"
        color="white"
        width="2"
        position="0.5 -0.4 0"
        text="color:white;value:''Tap Anywhere To Play Again'' ;shader: msdf; font:https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/pressstart2p/PressStart2P-Regular.json;"
      ></a-entity>)} */}

    {/* {GameState.Over ?
      (<a-entity
        color="white"
        width="2"
        position="0.7 0 0"
        text="color:#4d4d4d;value:GAME {'/n'} OVER! ;shader: msdf; font:https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/pressstart2p/PressStart2P-Regular.json;"
      ></a-entity>) : (<></>)} */}

    {/* Game User Integace dashboard */}
    {occluderReady ?
      (
        <a-plane
          id="gameDashboard"
          dashboardcontroller
          width="1" height="0.286627"
          src={GameUIdashboard}
          opacity="0.99"
          position="0 0 -0.04" >
        </a-plane>
      ) :
      (<a-plane
        id="gameDashboard"
        width="1" height="0.286627"

        opacity="0"
        position="0 0 -0.05" />)}


    <a-gltf-model
      id="occluderblock"
      occluder
      gltf-model="https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/gamification/occluder1.glb"
      position="0 0 -0.02"
      rotation="90 0 0"
      scale="0.5 0.15 0.5"
    >
    </a-gltf-model>


    {GameState.Start ? (<a-plane charactercontroller
      color="#4d4d4d" src={character} opacity="0.5"
      id="char" height="0.05" width="0.05" position="-0.35 0 0"
    ></a-plane>) :
      (<a-plane
        color="#4d4d4d" src={character} opacity="0.5"
        id="char" height="0.05" width="0.05" position="-0.35 0 0"
      ></a-plane>)}

    {!GameState.Start ? (
      <>
        {/* <a-entity
          geometry="primitive:plane;
                     height:0.15;
                    width:0.15;"
          class="clickable"
          material={"shader:gif;transparent:true;src:url(https://reality-reach-assets.storage.googleapis.com/ar-card/images/gamification/flappy/button.gif);opacity:0.99;"}
          position="0.35 0 0"
          onClick={() => { RequestGameStart() }}
        ></a-entity> */}

        <a-plane
          class="clickable"
          onClick={() => { RequestGameStart() }}
          width="0.159" height="0.1"
          src={GameStartButton}
          opacity="0.8"
          position="0.35 0 0" >
        </a-plane>
      </>
    ) :
      (<></>)}
    {/*  <a-box position="-1 0.5 -3" rotation="0 45 0" color="#4CC3D9"></a-box>
    <a-sphere position="0 1.25 -5" radius="1.25" color="#EF2D5E"></a-sphere>
    <a-cylinder position="1 0.75 -3" radius="0.5" height="1.5" color="#FFC65D"></a-cylinder>
    <a-plane position="0 0 -4" rotation="-90 0 0" width="4" height="4" color="#7BC8A4"></a-plane>
    <a-sky color="#ECECEC"></a-sky> */}


  </>)
}