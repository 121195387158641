import {
    getDatabase, ref, getStorage,
    get, child, sRef, strRef, getDownloadURL
} from "../../components/firebase";
import checkUserVrConfig from "../../components/VR/userVrConfig";



export function CheckTileConfig(
    { uid, profileHash, profileSnapshot,
        setPageOneFiles, setPrototileState,
        setPdfTileState }) {

    if (profileSnapshot) {
        if (profileSnapshot.UserDetails) {
            /*   setPrototile(snapshot.val().Prototile) */
            //setPrototileState({ ...prototileState, activeTile: snapshot.val().ProtoTile })
            setPrototileState(prevState => {
                return {
                    ...prevState,
                    activeTile: profileSnapshot.UserDetails.Prototile
                };
            });
            setPdfTileState(prevState => {
                return {
                    ...prevState,
                    activeTile: profileSnapshot.UserDetails.PdfTile
                }
            })
            console.log(profileSnapshot.UserDetails)

            // UpdatePrototile(snapshot.val().ProtoTile)
            // alert(snapshot.val().Prototile)
            if (profileSnapshot.UserDetails.Prototile == "Logo" || profileSnapshot.UserDetails.PdfTile == "Logo") {
                const storage = getStorage()
                getDownloadURL(sRef(storage, 'vision/users/' + profileHash + "/Images/Logo"))
                    .then((url) => {
                        console.log(url)
                        setPageOneFiles(prevState => {
                            return {
                                ...prevState,
                                Logo: url
                            }
                        })
                    })
                    .catch((error) => {
                    });
            }
        }
        else {
            /* alert('problem') */
        }
    }
}



export async function FetchUserFiles({ profileHash, setPageOneFiles }) {
    const db = getDatabase()
    var dbRef = ref(db)
    console.log(profileHash)

    async function getStorageAndSetProperty(fileArray, path, title) {
        const db = getDatabase()
        var dbRef = ref(db)
        const storage = getStorage()

        await getDownloadURL(strRef(storage, "vision/users/" + profileHash + path + title))
            .then((url) => {
                console.log(url)
                fileArray.push(url)
            })
            .catch((error) => {
                // Handle any errors
                /*  alert(error) */
                fileArray.push(null)
            });
    }


    var fileArray = []
    await getStorageAndSetProperty(fileArray, "/Images/", "HeaderTile")
    await getStorageAndSetProperty(fileArray, "/", "PDF")

    console.log(fileArray)

    /* let blob = await fetch(imageArray[0]).then(r => r.blob())
      .then(res => console.log(res)); */
    /*  console.log(blob) */

    setPageOneFiles(
        prevState => {
            return {
                ...prevState,
                HeaderTile: fileArray[0],
                PDF: fileArray[1]
            }
        })
}




export function FetchIconTrayDetails(uid, profileSnapshot, setIconTrayDetails) {

    if (profileSnapshot) {
        if (profileSnapshot.UserDetails) {
            if (profileSnapshot.UserDetails.IconTray) {
                console.log(profileSnapshot.UserDetails.IconTray)
                setIconTrayDetails({
                    One: profileSnapshot.UserDetails.IconTray.One,
                    Two: profileSnapshot.UserDetails.IconTray.Two,
                    Three: profileSnapshot.UserDetails.IconTray.Three,
                    Four: profileSnapshot.UserDetails.IconTray.Four,
                    Call: profileSnapshot.UserDetails.IconTray.Call,
                    Email: profileSnapshot.UserDetails.IconTray.Email,
                    Website: profileSnapshot.UserDetails.IconTray.Website,
                    LinkedIn: profileSnapshot.UserDetails.IconTray.LinkedIn,
                    WhatsApp: profileSnapshot.UserDetails.IconTray.WhatsApp,
                    Location: profileSnapshot.UserDetails.IconTray.Location,
                    Instagram: profileSnapshot.UserDetails.IconTray.Instagram
                })
            }
        }
    }

}

export async function GetProfileSnapshot({ uid,
    setProfileHash, setProfileSnapshot, setVrConfig,
    setCanvasInteraction, setProfileState }) {
    const db = getDatabase()
    var dbRef = ref(db)

    get(child(dbRef, "vision/builder/users/" + uid)).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val())
            setProfileSnapshot(snapshot.val())
            GetProfileState({
                uid, setProfileHash,
                profileSnapshot: snapshot.val(),
                setVrConfig,
                setCanvasInteraction
            })
        }
        else {
            alert('Please create your profile before accessing the test experience')
        }
    }
    ).catch((error) => {
        alert(error)
    })
}

export async function GetProfileState({ uid, setProfileHash,
    profileSnapshot,
    setVrConfig,
    setCanvasInteraction, }) {

    // console.log(snapshot.val())
    if (profileSnapshot) {
        if (profileSnapshot.ProfileHash) {
            setProfileHash(profileSnapshot.ProfileHash)
            checkUserVrConfig(uid, profileSnapshot.ProfileHash, setVrConfig, profileSnapshot.UserDetails)
        }
        else {
            alert('Please create your profile before accessing the test experience')
        }

        if (profileSnapshot.UserDetails) {
            if (profileSnapshot.UserDetails.Canvas) {
                setCanvasInteraction(profileSnapshot.UserDetails.Canvas.interactionSelection)
            }
        }
        else {
            alert('Please create your profile before accessing the test experience')
        }

        if (profileSnapshot.ProfileState) {
            /* setProfileState(profileSnapshot.ProfileState) */
        }
        else {
            alert('Please create your profile before accessing the test experience')
        }
    }
    else {
        alert('Please create your profile before accessing the test experience')
    }

}
