import { useState } from "react";
import { ARWebsitePopup, Call, Email, Instagram, LinkedIn, WebsitePopup, WhatsApp } from "../../functions/actionFunctions";


export default function ARcontactIcons({ iconTrayDetails }) {

    const [websitePopup, setWebsitePopup] = useState(false)


    function InvokeIconFunction(icon) {
        switch (iconTrayDetails[icon]) {
            case "Call": Call(iconTrayDetails.Call); break;
            case "Email": Email(iconTrayDetails.Email); break;
            case "Website": setWebsitePopup(true); break;
            case "LinkedIn": LinkedIn(iconTrayDetails.LinkedIn); break;
            case "WhatsApp": WhatsApp(iconTrayDetails.WhatsApp); break;
            case "Instagram": Instagram(iconTrayDetails.Instagram); break;
        }
    }


    return (
        <>


            <ARWebsitePopup {...{
                value: iconTrayDetails.Website,
                websitePopup,
                setWebsitePopup
            }} />


            <a-plane
                width="0.28"
                class="ui clickable"
                shader="flat"
                height="0.2"
                opacity='0'
                position="-0.35 0.75 0"
                onClick={() => { InvokeIconFunction("One") }}
            ></a-plane>

            <a-plane
                width="0.28"
                class="ui clickable"
                shader="flat"
                height="0.2"
                opacity='0'
                position="0.35 0.75 0"
                onClick={() => { InvokeIconFunction("Two") }}
            ></a-plane>

            <a-plane
                width="0.28"
                class="ui clickable"
                shader="flat"
                height="0.2"
                opacity='0'
                position="-0.35 0.50 0"
                onClick={() => { InvokeIconFunction("Three") }}
            ></a-plane>

            <a-plane
                width="0.28"
                class="ui clickable"
                shader="flat"
                height="0.2"
                opacity='0'
                position="0.35 0.50 0"
                onClick={() => { InvokeIconFunction("Four") }}
            ></a-plane>
        </>)
}

