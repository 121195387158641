import React, { useEffect, useRef, useState } from 'react';

/* import 'aframe'; */
//import 'aframe-gif-shader'
/* import 'mind-ar/dist/mindar-image.prod.js';
import 'mind-ar/dist/mindar-image-aframe.prod.js'; */
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MindARViewer from './mindar-viewer';
import { jump, DoNothing } from './components/Canvas/GameFunctions';
import { InitializeComponents } from './components/Canvas/Flappy/aframeComponents';
import EventHandler from './components/Canvas/EventHandler';
import { Button } from '@mui/material';
import StaticProfile from './components/Static/StaticProfile';

function App() {

  ////Game Char Control
  const jumping = useRef({
    jumping: false
  });

  const [GameState, setGameState] = useState({
    Start: false,
    Pasued: false,
    ResetSwitch: false,
    Over: false,
    inGame: false
  })

  useEffect(() => {
    console.log(GameState.inGame)
  }, [GameState])

  return (<>
    
    <Router>

      <div className="App">
        <Routes>
          <Route exact path='/' element={
            <div className="App" onClick={() => { GameState.Start ? jump(GameState, setGameState, jumping) : DoNothing() }}>

              <div className="container"
              >
                <MindARViewer {...{ jumping, GameState, setGameState }} />
                {GameState.inGame ?
                  (
                    <EventHandler
                      {...{
                        GameState,
                        setGameState,
                        jumping
                      }} />)
                  :
                  null}
                <video></video>
              </div>
            </div>}>
          </Route>

          <Route exact path='/static' element={<StaticProfile />}>

          </Route>
        </Routes>
      </div>
    </Router>

  </>
  );
}

export default App;
