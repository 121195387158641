import { useEffect, useState } from 'react'
import DialogTitle from '@mui/material/DialogTitle'

import { Modal, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'

import googlePic from '../assets/images/google.png'

import { initializeApp } from "firebase/app"
import { Init, auth, provider, signInWithEmailAndPassword, signOut, signInWithPopup, GoogleAuthProvider } from './firebase'
import { set, ref, db, update, database } from './firebase'
import { firebaseConfig } from './firebase'
import { browserSessionPersistence, setPersistence } from 'firebase/auth'

export default function Auth({ openAuth, setOpenAuth, setAuthStatus, setUid }) {



  useEffect(() => {
    const init = Init()
    const app = initializeApp(firebaseConfig)
    const user = auth.currentUser
    if (user) {
      console.log("completed")
      setAuthStatus("Completed")
      
      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          /* return signInWithEmailAndPassword(auth, email, password); */
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
        });
      //alert(user.uid)
    } else {

    }
  }, [auth.currentUser, auth.onAuthStateChanged])


  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setOpenAuth(false)
  }

  console.log("auth req")

  function loginUser() {
    var email = document.getElementById('emailL').value;
    var password = document.getElementById('passwordL').value;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;

        const dt = new Date();
        update(ref(database, 'users/' + user.uid), {
          last_test_login: dt,
        })
        setUid(user.uid)
        /* alert(user.uid); */
        setAuthStatus("Completed")
        handleClose()

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        alert(errorMessage);
      });
  }

  function googleAuth() {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user
        var uid = user.uid
        setUid(user.uid)
        /*  alert(user.uid); */
        setAuthStatus("Completed")
        handleClose()

        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        alert(errorMessage);
        // The AuthCredential type that was used.
        //const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  return (

    <div>
      <Dialog onClose={handleClose} open={openAuth}>
        <DialogTitle>
          Login to your Account
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To access the test experience, please login with your Reality Reach credentials.<br>
            </br>
            <Typography style={{ fontWeight: 600 }}>Note:</Typography>
            <li>
              Users do not need to login to view subscribed profiles.
            </li>
            <li>
              Marker tracking works best on physical, printed media.
            </li>

          </DialogContentText>


          <Stack direction="column" spacing={2} divider={<Divider orientation="horizontal" flexItem />}>

            <div direction="column" spacing={2}>
              <Stack>
                <TextField required
                  label="Your Email"
                  id="emailL"
                  type="email"
                  variant="filled"
                  size="small">Email</TextField>
                <TextField required
                  label="Your Password"
                  id="passwordL"
                  type="password"
                  variant="filled"
                  size="small">Password</TextField>
                <DialogActions>
                  <Button onClick={() => {/*  alert("login commandrec"); */ loginUser() }}>Login</Button>
                </DialogActions>

              </Stack>
            </div>

            <div>
              <DialogContentText>Sign-In with</DialogContentText>
              <img height={100}
                width={100}
                onClick={googleAuth}
                alt="Google Authenticator"
                src={googlePic}>
              </img>
            </div>

          </Stack>

          <DialogActions>
            <Button onClick={() => { handleClose() }}>close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}


