// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

import { getDatabase, ref as dbRef, push, get as dbget, set as dbSet, child as dbChild, update as dbUpdate, remove, runTransaction } from "firebase/database"
import { getStorage, ref as strRef, uploadBytesResumable as sUploadBytesResumable, getDownloadURL as sgetDownloadURL } from "firebase/storage"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

import {
  getAuth,
  createUserWithEmailAndPassword as createUser,
  signInWithEmailAndPassword as loginUser,
  onAuthStateChanged as authChange,
  signOut as logout,
  GoogleAuthProvider as Gauth,
  signInWithPopup as popup,
  setPersistence,
  browserSessionPersistence
} from "firebase/auth"

export const firebaseConfig = {
  apiKey: "AIzaSyCIb9RO9-d5Ercyx6LduKwzDVc2sACQfYk",
  authDomain: "reality-reach.firebaseapp.com",
  databaseURL: "https://reality-reach-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "reality-reach",
  storageBucket: "reality-reach.appspot.com",
  messagingSenderId: "934943777462",
  appId: "1:934943777462:web:71db3bfa3712c50536fc17",
  measurementId: "G-21HCP8SSHD"
};

export const Init = () => {
  var app = initializeApp(firebaseConfig)
  //eslint-disable-next-line
  //self.FIREBASE_APPCHECK_DEBUG_TOKEN = 'd6e4cd50-b988-4df6-a0cd-24ca391fcfb3';
  if (window) {
    //window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true

    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider('6LdirewhAAAAAJr1OARzqm3STIOv_HjU_lScRMOF'),

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      /* isTokenAutoRefreshEnabled: true */
    });
  }
}


// Initialize Firebase
const app = initializeApp(firebaseConfig)
//const analytics = getAnalytics(app);
export const db = getDatabase()
export const get = dbget
export const set = dbSet
export const ref = dbRef
export const child = dbChild
export const update = dbUpdate

export const GoogleAuthProvider = Gauth
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export const createUserWithEmailAndPassword = createUser
export const signInWithEmailAndPassword = loginUser
export const onAuthStateChanged = authChange
export const signOut = logout
export const signInWithPopup = popup

export const database = getDatabase(app);

export const uploadBytesResumable = sUploadBytesResumable
export const getDownloadURL = sgetDownloadURL
export const storage = getStorage()
export const sRef = strRef


export { getDatabase, getStorage, strRef }



