import PageOne from '../pages/pageOne'
import VisionAR from './vision'
import { db, ref, get, child } from '../firebase'
import { useState } from 'react'
import FlappyOnScreenInstructions from '../Canvas/Flappy/FlappyOnScreenInstructions'
import CanvasManager from '../Canvas/CanvasManager'

export default function ARexperienceManager({
    uid, profileHash, profileSnapshot, authStatus,

    aframeComponentsInitialized, setAframeComponentsInitialized,

    GameState, setGameState, jumping, GameUI,

    sceneRef, tabHasFocus, uiVisible, setUiVisible, setMode }) {


    return (
        <>
            <a-scene ref={sceneRef}
                class="clickable"
                mindar-image="imageTargetSrc: https://reality-reach-assets.storage.googleapis.com/ar-card/markers/Builder_Test.mind; 
     autoStart: false; 
      uiError: no; 
     uiScanning: #example-scanning-overlay;"
                color-space="sRGB"
                embedded renderer="colorManagement: true, physicallyCorrectLights, maxCanvasWidth: 3000;
     maxCanvasHeight: 3000;"
                vr-mode-ui="enabled: false"
                device-orientation-permission-ui="enabled: false">

                {tabHasFocus ? (
                    <a-camera position="0 0 0" look-controls="enabled: false" cursor="fuse: false; rayOrigin: mouse;"
                        raycaster="far: 10000; objects: .clickable">
                    </a-camera>
                ) :
                    (<></>)
                }

                <a-entity mindar-image-target="targetIndex: 0">
                    {uid ? (
                        <>
                            <a-entity  >
                                <VisionAR {...{
                                    uid, profileHash, profileSnapshot,
                                    authStatus,
                                    uiVisible, setMode
                                }} />
                            </a-entity>
                            <CanvasManager {...{
                                uid, profileHash, profileSnapshot, authStatus,

                                aframeComponentsInitialized, setAframeComponentsInitialized,

                                GameState, setGameState, jumping, GameUI,

                                sceneRef, tabHasFocus, uiVisible, setUiVisible, setMode
                            }} />

                            {/* <InkTeract /> */}
                        </>
                    )
                        :
                        (<>
                        </>)}
                </a-entity>
            </a-scene>
        </>
    )
}