import React, { useState, useEffect, useRef } from 'react';
import './components/styles.css'
import ARexperienceManager from './components/AR/ARexperienceManager';
import Auth from './components/Authentication'
import { onAuthStateChanged, auth, signOut, ref, db, get, child, Init } from './components/firebase'
import Flappy from './components/Canvas/Flappy/flappy';
import VRexperienceManager from './components/VR/VRexperienceManager';

import { Button, Dialog, DialogActions, Stack, DialogContent, Typography } from '@mui/material';
import FlappyOnScreenInstructions from './components/Canvas/Flappy/FlappyOnScreenInstructions';
import ScanningOverlay from './components/ScanningOverlay/ScanningOverlay';
import { InitializeComponents } from './components/Canvas/Flappy/aframeComponents';
import { GetProfileSnapshot } from './functions/db-functions/profileFunctions';

export default ({ jumping, GameState, setGameState }) => {
  const sceneRef = useRef(null);

  const [openAuth, setOpenAuth] = useState(false)
  const [authStatus, setAuthStatus] = useState("Not Started")

  const [profileHash, setProfileHash] = useState(null)
  const [profileState, setProfileState] = useState(null)
  const [uid, setUid] = useState(null)
  const [canvasInteraction, setCanvasInteraction] = useState(null)
  
  const [arSystem, setArSystem] = useState(null)

  const [profileSnapshot, setProfileSnapshot] = useState(null)
  const [tabHasFocus, setTabHasFocus] = useState(true);
  const [uiVisible, setUiVisible] = useState(true)

  const [mode, setMode] = useState("AR")

  const [vrConfig, setVrConfig] = useState({
    Complete: false,
    LeadGeneration: true,
    Image: null,
    ButtonPrompt: null,
    Email: null
  })

  const [aframeComponentsInitialized, setAframeComponentsInitialized] = useState({
    General: false,
    Canvas: false
  })

     ////This loop is required for the first entry to the webpage 
     useEffect(() => {
      const sceneEl = sceneRef.current;
      var arSys
      if (sceneEl) {
          setTimeout(function () {
              setArSystem(sceneEl.systems["mindar-image-system"])
          })
          arSys = sceneEl.systems["mindar-image-system"]
          console.log(arSys)
      }
      if (mode == "AR" && arSys) {
          const handleFocus = () => {
              console.log('Tab has focus');
              arSys.start()
              setTabHasFocus(true);
          };

          const handleBlur = () => {
              console.log('Tab lost focus');
              arSys.stop()
              setTabHasFocus(false);
          };

          window.addEventListener('focus', handleFocus);
          window.addEventListener('blur', handleBlur);

          sceneEl.addEventListener('renderstart', () => {
              arSys.start(); // start AR 
          });
          return () => {
              window.removeEventListener('focus', handleFocus);
              window.removeEventListener('blur', handleBlur);
              arSys.stop();
          }
      }
  }, [mode, sceneRef])


  ////This loop is required for the refresh to work 
  useEffect(() => {
      const sceneEl = sceneRef.current;

      console.log(arSystem)

      if (mode == "AR" && arSystem) {
          const handleFocus = () => {
              console.log('Tab has focus');
              arSystem.start()
              setTabHasFocus(true);
          };

          const handleBlur = () => {
              console.log('Tab lost focus');
              arSystem.stop()
              setTabHasFocus(false);
          };

          window.addEventListener('focus', handleFocus);
          window.addEventListener('blur', handleBlur);

          sceneEl.addEventListener('renderstart', () => {
              arSystem.start(); // start AR 
          });
          return () => {
              window.removeEventListener('focus', handleFocus);
              window.removeEventListener('blur', handleBlur);
              arSystem.stop();
          }
      }
  }, [mode, sceneRef, arSystem]);

  /* useEffect(() => {

    const THREE = window.THREE
    const scriptTag = document.createElement('script');
    if (THREE) {

      scriptTag.src = "https://reality-reach-assets.storage.googleapis.com/vision/general-assets/shader.js";
      scriptTag.async = true;

      document.body.appendChild(scriptTag);
    }

    return () => {
      document.body.removeChild(scriptTag);
    }
  }, []); */


  const GameUI = useRef({
    Dashboard: false,
    Instructions: false,
    Button: false
  })

  useEffect(() => {
    const init = Init()
    const user = auth.currentUser
    auth.onAuthStateChanged(user => {
      if (user) {
        if (user.isAnonymous) {
          signOut(auth)
          setOpenAuth(true)
        }
        console.log(user)
        setAuthStatus("Completed")
        setUid(user.uid)
        GetProfileSnapshot({
          uid: user.uid, setProfileHash,
          setProfileSnapshot,
          setVrConfig,
          setCanvasInteraction
        })
      }
      else {
        setOpenAuth(true)
        setAuthStatus("Started")
      }
    })

    //const script = document.createElement("script")
    //script.src="https://armeta-card-builder.appspot.com.storage.googleapis.com/assets/scripts/shader.js"
  }, [auth.currentUser]);

  useEffect(() => {
    const AFRAME = window.AFRAME

    AFRAME.registerComponent("portfolioanimations", {
      init: function () {
        console.log("animation controller registered")
        this.el.setAttribute("animation", {
          property: "position",
          from: "0 0 0",
          to: "0 0 -0.3",
          dur: 500,
          'startEvents': 'startGame',
        });

        this.el.setAttribute("animation__3", {
          property: "position",
          from: "0 0 -0.3",
          to: "0 0 0",
          dur: 500,
          'startEvents': 'endGame',
        });
        setAframeComponentsInitialized(prevState => {
          return { ...prevState, General: true }
        })
      }
    })

  }, []);

  return mode == "AR" ? (<div
    style={{
      height: '100vh',
      width: '100%',
    }}
  >
     <Auth {...{ openAuth, setOpenAuth, setAuthStatus, setUid }} />

    <ScanningOverlay {...{ profileSnapshot }} />

    <ARexperienceManager
      {...{
        uid, profileHash, profileSnapshot, authStatus,

        aframeComponentsInitialized, setAframeComponentsInitialized,

        GameState, setGameState, jumping, GameUI,

        sceneRef, tabHasFocus, uiVisible, setUiVisible, setMode
      }}
    />
  </div>
  ) :
    mode == "VR" ?
      (<>
        <VRexperienceManager {...{
          setMode, vrConfig
        }} />
      </>)
      : (<></>)
}

